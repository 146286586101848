import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import useTypingEffect from 'hooks/useTypingEffect';

function TypingTrans({ i18nKey, speed, highlightWords = [] }) {
  const { t } = useTranslation();
  const fullText = t(i18nKey);
  const typedText = useTypingEffect(fullText, speed);
  const fullWords = fullText.split(' ');
  const typedWords = typedText.split(' ');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {fullWords.map((word, index) => {
        const typedPortion = typedWords[index] || '';
        const remainingPortion = word.slice(typedPortion.length);

        return (
          <Box
            component="span"
            key={`${word}-${uuidv4()}`}
            sx={{
              display: 'inline-block',
              position: 'relative',
              isolation: 'isolate',
              fontWeight: highlightWords.includes(word) ? 'bold' : 'normal',
              p: 0.5,
              '@keyframes expandRight': {
                from: {
                  transform: 'scaleX(0)',
                },
                to: {
                  transform: 'scaleX(1)',
                },
              },
            }}
          >
            {highlightWords.includes(word) && !isSmallScreen && typedPortion === word && (
              <Box
                component="span"
                sx={{
                  position: 'absolute',
                  inset: 0,
                  backgroundColor: 'transparentBg.main',
                  transformOrigin: 'left',
                  animation: 'expandRightWithFade 0.3s ease-in forwards',
                  zIndex: 1,
                  '@keyframes expandRightWithFade': {
                    '0%': {
                      transform: 'scaleX(0)',
                      opacity: 0,
                    },
                    '1%': {
                      opacity: 1,
                    },
                    '100%': {
                      transform: 'scaleX(1)',
                      opacity: 1,
                    },
                  },
                }}
              />
            )}
            <Box
              component="span"
              sx={{
                position: 'relative',
                zIndex: 2,
                color: highlightWords.includes(word) ? '#FFFFFF' : 'ourOrange.main',
              }}
            >
              {typedPortion}
              <span style={{ visibility: 'hidden' }}>{remainingPortion}</span>
            </Box>
          </Box>
        );
      })}
    </>
  );
}

export default TypingTrans;
