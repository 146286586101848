import React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, LinearProgress, Typography } from '@mui/material';
import Footer from './Footer';
import LaboxApps from './icons/LaboxApps';

export default function LoadingPage({ progress }) {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <LaboxApps width="130" />
      {progress !== undefined ? (
        <Box sx={{
          width: '200px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1,
        }}
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ width: '100%' }}
          />
          <Typography variant="body2" color="text.secondary">
            {Math.round(progress)}
            %
          </Typography>
        </Box>
      ) : (
        <CircularProgress />
      )}
      <Footer minimal />
    </Box>
  );
}
