import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import laboxlogo from 'staticFiles/laboxlogo.svg';
import {
  Menu, MenuItem, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import laboxapplications from 'staticFiles/laboxapplications-white.svg';
// import laboxapplications from 'staticFiles/laboxapplications-blue.svg';
import LanguageSelectToggle from './LanguageSelectToggle';

const hoverUnderlineStyle = {
  '&:hover::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -12,
    width: '100%',
    height: '3px',
    backgroundColor: 'ourOrange.main',
  },
};

export default function TopToolbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setIsAtTop(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      {isSmallScreen ? (
        <Box
          component="img"
          src={laboxlogo}
          sx={{
            position: 'absolute',
            left: 10,
            top: 6,
            width: 'auto',
            height: { xs: 40, sm: 55, md: 55 },
            cursor: 'pointer',
            p: 0.5,
            border: 2,
            borderRadius: 1,
            borderColor: 'transparentBg.main',
          }}
          onClick={() => {
            navigate('/');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      ) : (
        <Box
          component="img"
          src={isAtTop ? laboxapplications : laboxlogo}
          onClick={() => {
            navigate('/');
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          sx={{
            position: 'absolute',
            left: 20,
            top: 5,
            width: 'auto',
            height: { xs: 35, sm: 50 },
            p: 0.5,
            cursor: 'pointer',
            transition: 'all 0.3s ease-in-out',
            opacity: 1,
            ...(isAtTop ? {} : {
              border: 2,
              borderRadius: 1,
              borderColor: 'transparentBg.main',
            }),
          }}
        />
      )}

      {/* <Box
        component="img"
        src={laboxapplications}
        sx={{
          position: 'absolute',
          left: 10,
          top: 10,
          width: 'auto',
          height: { xs: 45, sm: 55, md: 65 },
          cursor: 'pointer',
          p: 1,
          // backgroundColor: 'transparentBg.main',
          border: 2,
          borderRadius: 1,
          borderColor: 'transparentBg.main',
        }}
        onClick={() => navigate('/')}
      /> */}
      {isMobile ? (
        <MobileToolBar />
      ) : (
        <ComputerToolBar />
      )}

    </Box>
  );
}

function MobileToolBar() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const goTo = (link) => {
    navigate(link);
    handleClose();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: { xs: 2, sm: 3 },
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'transparentBg.main',
        p: 1,
      }}
    >
      <SolutionsMenu />
      <TeamMenu />
      <Typography
        aria-label="menu"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        Menu
      </Typography>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => goTo('/')}>{t('home')}</MenuItem>
        <MenuItem>
          <a
            target="_blank"
            href="https://labox-alcoometrie-blog.com/"
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              color: 'inherit',
            }}
            rel="noreferrer"
          >
            Blog
          </a>
        </MenuItem>
        <MenuItem onClick={() => goTo('/contact')}>
          <ToolbarButton
            text="Contact"
            link="/contact"
          />
        </MenuItem>
        <MenuItem>
          <LanguageSelectToggle height={32} />
        </MenuItem>
      </Menu>
    </Box>
  );
}

function ComputerToolBar() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        alignItems: 'center',
        backgroundColor: 'transparentBg.main',
        p: 1,
      }}
    >
      <ToolbarButton
        text={t('home')}
        link="/"
      />
      <SolutionsMenu />
      <Box
        component="a"
        target="_blank"
        href="https://labox-alcoometrie-blog.com/"
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          color: 'inherit',
          display: 'inline-block',
          position: 'relative',
          ...hoverUnderlineStyle,
        }}
        rel="noreferrer"
      >
        Blog
      </Box>
      <TeamMenu />
      <ToolbarButton
        text="Contact"
        link="/contact"
      />
      <LanguageSelectToggle height={32} />
    </Box>
  );
}

function ToolbarButton({ text, link }) {
  const navigate = useNavigate();

  return (
    <Typography
      sx={{
        cursor: 'pointer',
        position: 'relative',
        ...hoverUnderlineStyle,
      }}
      onClick={() => navigate(link)}
    >
      {text}
    </Typography>
  );
}

function SolutionsMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Typography
        id="solutions-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          ...hoverUnderlineStyle,
        }}
      >
        {t('solutions')}
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
          navigate('/alcoholometry');
          handleClose();
        }}
        >
          {t('alcoholometry.title')}
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/tanker-trucks');
          handleClose();
        }}
        >
          {t('tt.title')}
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/gauged-containers');
          handleClose();
        }}
        >
          {t('gc.title')}
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/inventories');
          handleClose();
        }}
        >
          {t('inventories.title')}
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/optimix');
          handleClose();
        }}
        >
          {t('optimix.title')}
        </MenuItem>
      </Menu>
    </div>
  );
}

function TeamMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Typography
        id="solutions-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          position: 'relative',
          ...hoverUnderlineStyle,
        }}
      >
        {t('about_us.about')}
      </Typography>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
          navigate('/pierre');
          handleClose();
        }}
        >
          Pierre
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/remi');
          handleClose();
        }}
        >
          Rémi
        </MenuItem>
        <MenuItem onClick={() => {
          navigate('/evelyne');
          handleClose();
        }}
        >
          Évelyne
        </MenuItem>
      </Menu>
    </div>
  );
}
