// @ts-nocheck
import React from 'react';
import './App.css';
import 'fontsource-roboto';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import {
  useFirebaseApp,
  AnalyticsProvider,
  FirebaseAppProvider,
  useInitFirestore,
  FirestoreProvider,
} from 'reactfire';
import { getAnalytics } from 'firebase/analytics';
import CssBaseline from '@mui/material/CssBaseline';
import Home from 'components/Home';
import { initI18next } from 'i18n/i18next';
import { initializeFirestore } from 'firebase/firestore';
import LoadingPage from 'components/LoadingPage';
import { HelmetProvider } from 'react-helmet-async';

const firebaseConfig = {
  apiKey: 'AIzaSyCa9rLf4xmacQg6xdoxOUxrWFrz7N_34W4',
  authDomain: 'labox-apps.com',
  projectId: 'labox-ws',
  storageBucket: 'labox-ws.appspot.com',
  messagingSenderId: '669483452439',
  appId: '1:669483452439:web:9cee9296b437f1dc297452',
  measurementId: 'G-X9LGJ0JJVE',
};

const theme = createTheme({
  typography: {
    fontFamily: 'LaboxFont',
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#213d45',
      paper: '#213d45',
    },
    primary: {
      main: '#437A89',
      contrastText: 'white',
    },
    secondary: {
      main: '#73a9b9',
      contrastText: 'white',
    },
    text: {
      primary: '#FFFFFF',
    },
    deepPrimary: {
      main: '#2b4f5a',
      contrastText: 'white',
    },
    ourGreen: {
      main: '#99BD5B',
      contrastText: 'white',
    },
    ourOrange: {
      main: '#f4ad45',
      contrastText: 'white',
    },
    gold: {
      main: '#FFD700',
      contrastText: 'white',
    },
    darkgray: {
      main: '#0000008a',
      contrastText: 'white',
    },
    mediumgray: {
      main: '#8585858a',
      contrastText: 'white',
    },
    gray: {
      main: '#d8d8d8',
      contrastText: 'white',
    },
    white: {
      main: '#FFFFFF',
      contrastText: 'black',
    },
    transparentBg: {
      main: 'rgba(66, 122, 137, 0.9)',
      contrastText: 'white',
    },
  },
});

function ProvidersWithLoading() {
  const { status: firestoreStatus, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const savedSetting = localStorage.getItem('forceLongPolling');
    const forceLongPolling = savedSetting === 'true';
    const baseSettings = {};

    if (forceLongPolling) {
      baseSettings.experimentalForceLongPolling = true;
    } else {
      baseSettings.experimentalAutoDetectLongPolling = true;
    }
    const fsSettings = (process.env.NODE_ENV === 'development')
      ? {
        ...baseSettings,
        host: 'localhost:4401',
        ssl: false,
      } : baseSettings;

    const db = initializeFirestore(firebaseApp, fsSettings);
    return db;
  });

  if (firestoreStatus === 'loading') {
    return (
      <LoadingPage />
    );
  }

  return (

    <FirestoreProvider sdk={firestoreInstance}>

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <>
            <CssBaseline />
            <Home />
          </>
        </ThemeProvider>
      </StyledEngineProvider>

    </FirestoreProvider>

  );
}

function AllReactFireProviders() {
  const app = useFirebaseApp();

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>
      <ProvidersWithLoading />
    </AnalyticsProvider>
  );
}

function App() {
  initI18next();
  return (
    <HelmetProvider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <div className="App" style={{ whiteSpace: 'pre-line' }}>
          <AllReactFireProviders />
        </div>
      </FirebaseAppProvider>
    </HelmetProvider>
  );
}

export default App;
