import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';

export default function CopyToClipboard(props) {
  const { t } = props;
  const [open, setOpen] = useState(false);
  const { toBeCopied, child } = props;
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(toBeCopied);
  };

  return (
    <Tooltip
      title={t('copy_clipboard')}
    >
      <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
        {child}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message={t('copied_clipboard')}
        />
      </Box>
    </Tooltip>
  );
}
