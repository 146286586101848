import { useEffect } from 'react';
import keyboard from 'staticFiles/keyboard.jpg';
import phonecellar from 'staticFiles/phone-cellar.jpg';
import laptopdesk from 'staticFiles/laptop-desk.jpg';

export const images = [keyboard, phonecellar, laptopdesk];

export default function HeaderImages({ onLoad, onProgress }) {
  useEffect(() => {
    let loadedImages = 0;
    const totalImages = images.length;

    const handleImageLoad = () => {
      loadedImages += 1;
      const progress = (loadedImages / totalImages) * 100;
      onProgress?.(progress);

      if (loadedImages === totalImages) {
        onLoad();
      }
    };

    images.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = handleImageLoad;
      img.onerror = handleImageLoad;
    });
  }, [onLoad, onProgress]);

  return null;
}
