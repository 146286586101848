import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import pierre from 'staticFiles/pierre.jpeg';
import { Link } from 'react-router-dom';
import BackBoxes from 'components/BackBoxes';

export default function Pierre() {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: 9,
    }}
    >
      <BackBoxes />
      <Presentation />
      <Contact />
    </Box>
  );
}

function Presentation() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: { xs: 3, sm: 6, xl: 9 },
      px: { xs: 3, sm: '10%', xl: '15%' },
    }}
    >
      <Box
        component="img"
        sx={{
          width: 300,
          height: 300,
          borderRadius: '50%',
          p: 0.7,
          backgroundColor: 'primary.main',
          objectFit: 'cover',
        }}
        src={pierre}
        alt="pierre"
      />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: { xs: '100%', sm: '50%' },
        alignItems: 'flex-start',
        textAlign: 'left',
      }}
      >
        <Typography variant="h2">
          Pierre
        </Typography>
        <Typography variant="h4" color="ourOrange.main">
          {t('pierre.title')}
        </Typography>
        <Typography>
          <Trans
            i18nKey="pierre.info"
            components={{
              1: <Link
                style={{ color: '#FFFFFF' }}
                target="_blank"
                to="https://fr.wikipedia.org/wiki/D%C3%A9veloppeur_full_stack"
              />,
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}

function Contact() {
  const { t } = useTranslation();
  return (

    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: { xs: 3, sm: 6, xl: 9 },
      px: { xs: 3, sm: '10%', xl: '15%' },
      width: '100%',
    }}
    >
      <Typography variant="h3" sx={{ width: 300 }} color="primary.main">
        {t('contact_him')}
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        width: { xs: '100%', sm: '50%' },
        alignItems: 'flex-start',
        textAlign: 'left',
      }}
      >
        {/* eslint-disable */}
      <Typography>
        <Trans
          i18nKey="pierre.contact"
          components={{
            1: <a
              href="mailto:example@example.com"
              style={{ color: '#FFFFFF' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              pierre@labox-apps.com
            </a>,
            2: <Link
              style={{ color: '#FFFFFF' }}
              target="_blank"
              to="https://www.linkedin.com/in/pierre-chanson-7a817064/"
            />,
          }}
        />
      </Typography>
      {/* eslint-enable */}
      </Box>
    </Box>

  );
}
