import React from 'react';
import {
  Typography,
  Box,
} from '@mui/material';
// import lbxArrowRight from 'staticFiles/lbx_arrow_right.png';

export default function TextPoint({
  title, text, width = 250, isSelected,
}) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: 2,
      width,
    }}
    >
      {/* <Box component="img" src={lbxArrowRight} sx={{ width: 20, height: 'auto' }} /> */}
      <Typography sx={{
        typography: {
          fontSize: isSelected ? '1.5rem' : '1.3rem',
          transition: 'font-size 0.2s ease-in-out, color 0.2s ease-in-out',
          color: '#f4ad45',
        },
        textAlign: 'left',
      }}
      >
        {title}
      </Typography>
      <Typography sx={{
        typography: {
          xs: 'body2',
          md: 'body1',
        },
        textAlign: 'left',
      }}
      >
        {text}
      </Typography>
    </Box>
  );
}
