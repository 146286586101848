import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Typography,
} from '@mui/material';

export default function CardPoint({
  title, text, width = 300, isSelected,
}) {
  const [isHovered, setIsHovered] = useState(false);

  // Handle mouse enter/leave
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        width,
        backgroundColor: 'transparent',
        transform: isSelected ? 'scale(1.05)' : 'scale(1)',
        transition: 'all 0.3s ease-in-out',
        position: 'relative',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'deepPrimary.main',
          transition: 'transform 0.4s ease-in-out',
          transform: isSelected ? 'translateX(0)' : 'translateX(-100%)',
          zIndex: -1,
        },
      }}
    >
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        minHeight: 200,
        backgroundColor: 'transparent',
        gap: 2,
      }}
      >
        <Typography sx={{
          typography: {
            fontSize: isSelected ? '1.5rem' : '1.3rem',
            color: isSelected ? '#f4ad45' : 'white',
            transition: 'font-size 0.2s ease-in-out, color 0.2s ease-in-out',
            marginBottom: '-10px',
          },
          textAlign: 'left',
          fontWeight: 'bold',
        }}
        >
          {title}
        </Typography>
        <Typography sx={{
          typography: {
            xs: 'body2',
            md: 'body1',
          },
          textAlign: 'left',
        }}
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}
