import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import tank from 'staticFiles/tank.jpg';
import { Link } from 'react-router-dom';
import BackBoxes from 'components/BackBoxes';

export default function GaugedContainers() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: 9,
    }}
    >
      <BackBoxes />
      <Presentation />
      <Videos />
      <Points />
      <Typography sx={{ px: 3 }}>{t('interested_or_project')}</Typography>
      <Button sx={{ mt: -6 }} variant="contained" size="large" component={Link} to="/contact">
        {t('contact.title')}
      </Button>
    </Box>
  );
}

function Presentation() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: 9,
      px: { xs: 3, sm: '10%', xl: '15%' },
    }}
    >
      <Typography variant="h2">
        {t('gc.title')}
      </Typography>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: { xs: 3, sm: 6, xl: 9 },
      }}
      >
        <Link
          target="_blank"
          to="https://gc.digitank.labox-apps.com"
        >
          <Box
            component="img"
            sx={{
              width: 300,
              height: 300,
              borderRadius: 1,
              objectFit: 'cover',
            }}
            src={tank}
            alt="tank"
          />
        </Link>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          width: { xs: '100%', sm: '50%' },
          alignItems: 'flex-start',
          textAlign: 'left',
        }}
        >
          <Typography variant="h4" color="ourOrange.main">
            {t('gc.info1')}
          </Typography>
          <Typography>
            {t('gc.info2')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function Videos() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: 3,
      px: 1,
      width: '100%',
    }}
    >
      <Box
        sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: { xs: '100%', md: '45%' },
        }}
      >
        <Box
          component="iframe"
          sx={{
            width: '100%',
            maxWidth: 560,
            height: { xs: 280, md: 315 },
          }}
          src="https://www.youtube.com/embed/Vj9wWHtut9A"
          title={t('gc.video1.title')}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
        <Typography variant="h4" color="ourOrange.main">{t('gc.video1.title')}</Typography>
        <Typography variant="h6">{t('gc.video1.subtitle')}</Typography>
      </Box>
    </Box>
  );
}

function Points() {
  const { t } = useTranslation();
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexWrap: 'wrap',
      gap: 3,
      backgroundColor: 'deepPrimary.main',
      width: '100%',
      py: 6,
    }}
    >
      <Point title={t('gc.point1.title')} subtitle={t('gc.point1.subtitle')} />
      <Point title={t('gc.point2.title')} subtitle={t('gc.point2.subtitle')} />
      <Point title={t('gc.point3.title')} subtitle={t('gc.point3.subtitle')} />
    </Box>
  );
}

function Point({ title, subtitle }) {
  return (
    <Box
      sx={{
        display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, width: 350,
      }}
    >
      <Typography variant="h4" color="secondary.main">
        {title}
      </Typography>
      <Typography>
        {subtitle}
      </Typography>
    </Box>
  );
}
