import React, { useState } from 'react';
import {
  Box, AppBar, Toolbar,
} from '@mui/material';
import {
  BrowserRouter,
} from 'react-router-dom';
import useGlobal from 'global-state/store';
import { useTranslation } from 'react-i18next';
import Cookies from 'components/cookies/Cookies';
import TopToolbar from 'components/TopToolbar';
import Footer from './Footer';
import LaboxAppsRoutes from './LaboxAppsRoutes';
import GlobalSnackbar from './GlobalSnackbar';
import ScrollToTop from './ScrollToTop';
import LoadingPage from './LoadingPage';
import HeaderImages from './header/HeaderImages';
import SEO from './SEO';

export default function Home() {
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  return (
    <BrowserRouter>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        alignItems: 'center',
      }}
      >
        <SEO />
        <ScrollToTop />
        <GlobalSnackbar />
        <HeaderImages
          onLoad={() => setImagesLoaded(true)}
          onProgress={setLoadingProgress}
        />
        {imagesLoaded ? (
          <HomeContent />
        ) : (
          <LoadingPage progress={loadingProgress} />
        )}
        <Cookies
          cookieName="agreed-to-cookies-on-labox-apps"
          t={t}
          globalActions={globalActions}
        />
      </Box>
    </BrowserRouter>
  );
}

function HomeContent() {
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          width: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0)',
          m: 0,
          p: 0,
        }}
        color="inherit"
      >
        <Toolbar disableGutters sx={{ p: 0, m: 0 }}>
          <TopToolbar />
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100vw',
          alignItems: 'center',
          pt: { xs: 10, md: 12 },
        }}
      >
        <Box sx={{
          minHeight: '75vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          <LaboxAppsRoutes />
        </Box>
        <Footer />
      </Box>
    </>
  );
}
