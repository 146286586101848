import React from 'react';
import { Box } from '@mui/material';
import lbxCube from 'staticFiles/lbx_cube.svg';

export default function BackBoxes() {
  return (
    <Box sx={{
      position: 'absolute',
      top: 80,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 10,
      zIndex: -1,
      opacity: 0.3,
    }}
    >
      <Box
        component="img"
        src={lbxCube}
        sx={{
          maxWidth: 100,
          width: '10vw',
          height: 'auto',
          ml: '60vw',
        }}
      />
      <Box
        component="img"
        src={lbxCube}
        sx={{
          width: '15vw',
          maxWidth: 150,
          height: 'auto',
          ml: '10vw',
        }}
      />
      <Box
        component="img"
        src={lbxCube}
        sx={{
          width: '25vw',
          maxWidth: 250,
          height: 'auto',
          ml: '60vw',
        }}
      />
      <Box
        component="img"
        src={lbxCube}
        sx={{
          width: '80vw',
          maxWidth: 80,
          height: 'auto',
          ml: 0,
          mt: -30,
        }}
      />
      <Box
        component="img"
        src={lbxCube}
        sx={{
          width: '60vw',
          maxWidth: 60,
          height: 'auto',
          ml: '60vw',
          mt: 25,
        }}
      />
      <Box
        component="img"
        src={lbxCube}
        sx={{
          width: '10vw',
          maxWidth: 180,
          height: 'auto',
          ml: '90vw',
          mt: 40,
        }}
      />
    </Box>
  );
}
