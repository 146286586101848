import React, { useEffect, useState } from 'react';
import {
  Button, Typography, Link as MUILink,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Refresh from '@mui/icons-material/Refresh';
import version from 'staticFiles/version.txt';
import { Link } from 'react-router-dom';

export default function Footer({ minimal = false }) {
  const { t } = useTranslation();
  const [appVersion, setappVersion] = useState('');

  useEffect(() => {
    fetch(version)
      .then((r) => r.text())
      .then((text) => {
        setappVersion(text);
      }).catch((error) => {
        throw new Error(`Footer fetch version failed: ${error}`);
      });
  }, []);

  const knownDatabases = ['firebase-heartbeat-database',
    'firebase-installations-database', 'firebaseLocalStorageDb',
    'firebase_remote_config', 'firestore/[DEFAULT]/labox-ws/main'];

  const clearIndexedDB = () => {
    if (window.indexedDB.databases) {
      window.indexedDB.databases().then((databases) => {
        databases.forEach((database) => {
          window.indexedDB.deleteDatabase(database.name);
        });
      });
    } else {
    // Fallback to predefined list
      knownDatabases.forEach((dbName) => {
        window.indexedDB.deleteDatabase(dbName);
      });
    }
  };
  async function clearCacheStorage() {
    const keys = await caches.keys();
    keys.forEach(async (key) => {
      await caches.delete(key);
    });
  }

  const clearApplicationCache = () => {
    localStorage.clear();
    sessionStorage.clear();

    // Clearing cookies
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }

    clearIndexedDB();
    clearCacheStorage();

    window.location.reload();
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      pt: 6,
      pb: 3,
      backgroundColor: 'background.default',
      width: '100%',
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
      >

        {minimal === false && (
        <Typography component="span" variant="body2" style={{ marginTop: 10, width: '100%' }}>
          {t('welcome.contact_footer')}
          {' '}

          <MUILink
            component={Link}
            to="/contact"
            color="primary"
            sx={{ fontWeight: 'bold' }}
          >
            contact@labox-apps.com
          </MUILink>
        </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          <Typography variant="caption">
            {t('settings.app_version', { appVersion })}
          </Typography>

          <Button
            variant="text"
            size="small"
            onClick={() => window.location.reload()}
            endIcon={<Refresh />}
          >
            <Typography variant="caption">
              {t('reload')}
            </Typography>
          </Button>
        </Box>
        <Button
          sx={{ mt: 3, alignSelf: 'center' }}
          variant="text"
          size="small"
          onClick={clearApplicationCache}
          endIcon={<Refresh />}
        >
          <Typography variant="caption">
            {t('clear_cache')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
