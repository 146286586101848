import { useState, useEffect } from 'react';

export default function useTypingEffect(text, speed = 30) {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    setDisplayedText('');

    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayedText(text.slice(0, i + 1));
        i += 1;
      } else {
        clearInterval(typingInterval);
      }
    }, speed);

    return () => {
      if (typingInterval) {
        clearInterval(typingInterval);
      }
    };
  }, [text, speed]);

  return displayedText;
}
