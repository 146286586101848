import React from 'react';
import useGlobal from 'global-state/store';
import Snackbar from '@mui/material/Snackbar';
import MUIAlert from '@mui/material/Alert';

export default function GlobalSnackbar() {
  const [globalState, globalActions] = useGlobal();

  const handleClose = (event, reason) => {
    globalActions.closeSnackbar();
  };

  return (
    <Snackbar
      open={globalState.globalSnackbar.display}
      autoHideDuration={globalState.globalSnackbar.displayDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MUIAlert
        onClose={handleClose}
        severity={globalState.globalSnackbar.severity}
        sx={{ mt: 25, p: 5, boxShadow: 3 }}
      >
        {globalState.globalSnackbar.message}
      </MUIAlert>
    </Snackbar>
  );
}
