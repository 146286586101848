import React from 'react';
import { Box } from '@mui/material';
import guide from 'staticFiles/guide2.jpeg';

export default function BackgroundImages({ refToSwitch }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        zIndex: -1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {FirstImageBox()}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          zIndex: 1,
        }}
      />
    </Box>
  );
}

function FirstImageBox() {
  return (
    <Box
      component="img"
      src={guide}
      alt="guide"
      sx={{
        flex: 1,
        width: '100%',
        height: '100vh',
        objectFit: 'cover',
        objectPosition: 'center',
        transition: 'top 0.1s ease-out',
      }}
    />
  );
}
