import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import evelyne from 'staticFiles/evelyne-no-bg.png';
import pierre from 'staticFiles/pierre-no-bg.png';
import remi from 'staticFiles/remi-no-bg.png';
import diamond from 'staticFiles/diamond.svg';

export default function TheTeam() {
  const { t } = useTranslation();
  const lastRef = useRef(null);
  const mainBoxRef = useRef(null);
  const [bgHeight, setBgHeight] = useState(0);

  useEffect(() => {
    const updateBgHeight = () => {
      if (lastRef.current && mainBoxRef.current) {
        const buttonRect = lastRef.current.getBoundingClientRect();
        const containerRect = mainBoxRef.current.getBoundingClientRect();

        const buttonBottomRelativeToContainer = buttonRect.bottom - containerRect.top;
        const adjustedHeight = buttonBottomRelativeToContainer - (buttonRect.height / 2);

        setBgHeight(adjustedHeight);
      }
    };

    // Initial call to set the height
    updateBgHeight();

    // Add event listener for window resize
    window.addEventListener('resize', updateBgHeight);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', updateBgHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        pt: 10,
        pb: 30,
        backgroundColor: 'background.default',
      }}
    >
      <Box
        ref={mainBoxRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          position: 'relative',
        }}
      >
        <Box sx={{
          height: `${bgHeight}px`,
          zIndex: 1,
          width: '100%',
          backgroundColor: 'deepPrimary.main',
          position: 'absolute',
          top: 0,
        }}
        />
        <Typography
          variant="h2"
          color="primary.main"
          sx={{
            mt: -5, fontWeight: 'bold', mb: 6, zIndex: 2,
          }}
        >
          {t('theTeam')}
        </Typography>

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: 15,
          justifyContent: 'center',
          zIndex: 2,
        }}
        >
          <Profile
            name="Pierre Chanson"
            title={t('cofounder')}
            profileImg={pierre}
            link="pierre"
          />
          <Profile
            name="Rémi Delassus"
            title={t('cofounder')}
            profileImg={remi}
            link="remi"
          />
          <Profile
            lastRef={lastRef}
            name="Évelyne Chanson"
            title={t('consultant')}
            profileImg={evelyne}
            link="evelyne"
          />
        </Box>
      </Box>
    </Box>
  );
}

function Profile({
  profileImg, name, title, link, lastRef,
}) {
  return (
    <Link
      to={link}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        transition: 'transform 0.3s ease-in-out',
        '&:hover': {
          transform: 'scale(1.03)',
        },
      }}
      >

        <Box
          ref={lastRef}
          component="img"
          sx={{
            width: 200,
            height: 200,
            borderRadius: '50%',
            p: 0.7,
            backgroundColor: 'primary.main',
            objectFit: 'cover',
          }}
          src={profileImg}
          alt={name}
        />

        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          mt: 1,
          position: 'relative',
        }}
        >
          <Box
            component="img"
            sx={{
              position: 'absolute',
              width: 300,
              height: 'auto',
              p: 0.7,
              zIndex: -1,
              color: 'primary.main',
            }}
            src={diamond}
          />
          <Typography
            sx={{
              fontWeight: 'bold',
              color: 'secondary.main',
            }}
            variant="h6"
          >
            {name}
          </Typography>
          <Typography sx={{ color: 'text.primary' }}>
            {title}
          </Typography>
        </Box>
      </Box>

    </Link>
  );
}
