import React from 'react';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFormik } from 'formik';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SendIcon from '@mui/icons-material/Send';
import { logEvent } from 'firebase/analytics';
import CopyToClipboard from './CopyToClipboard';
import BackBoxes from './BackBoxes';

export default function Contact(props) {
  const {
    t, navigate, globalActions, sendEmail, analytics,
  } = props;
  const [success, setsuccess] = React.useState(false);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 3,
      mt: 3,
      width: '100%',
    }}
    >
      <BackBoxes />
      <Typography variant="h6" component="span">
        {t('contact.title')}
      </Typography>
      {!success && (
      <ContactForm
        t={t}
        analytics={analytics}
        setsuccess={setsuccess}
        globalActions={globalActions}
        sendEmail={sendEmail}
      />
      )}
      {success && (
      <Paper sx={{
        width: '100%',
        p: 3,
        maxWidth: 800,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        gap: 3,
      }}
      >
        <Typography variant="h6" component="span">
          {t('contact.success')}
        </Typography>
        <Button
          variant="contained"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('back')}
        </Button>
      </Paper>
      )}
    </Box>
  );
}

function ContactForm(props) {
  const [sending, setsending] = React.useState(false);
  const {
    setsuccess, t, globalActions, sendEmail, analytics,
  } = props;
  const [emailStatus, setemailStatus] = React.useState('initial');

  const formik = useFormik({
    initialValues: {
      email: '',
      nameAndCompany: '',
      message: '',
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = t('required');
      } if (!values.message) {
        errors.message = t('required');
      }
      return errors;
    },
    async onSubmit(values, { setSubmitting }) {
      try {
        setsending(true);
        await sendEmail(values);
        setsuccess(true);
      } catch (error) {
        console.log(error);
        globalActions.setSnackbarMessage({ message: t('contact.error'), severity: 'error' });
        setSubmitting(false);
        logEvent(analytics, 'contact_error', {
          appName: 'Labox Apps Web',
          error_message: error.message,
        });
      } finally {
        setsending(false);
      }
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
        width: '100%',
      }}
    >
      <Paper
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          width: '100%',
          p: 3,
          maxWidth: 800,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          gap: 3,
        }}
      >
        <Box sx={{
          display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 1, width: '100%',
        }}
        >
          <TextField
            style={{ flexGrow: 1 }}
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            label={t('contact.email')}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.errors.email && formik.touched.email && formik.errors.email}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            style={{ flexGrow: 1 }}
            name="nameAndCompany"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nameAndCompany}
            label={t('contact.nameAndCompany')}
            error={Boolean(formik.errors.nameAndCompany)}
            helperText={formik.errors.nameAndCompany && formik.errors.nameAndCompany}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <TextField
          fullWidth
          name="message"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          label={t('contact.message')}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.errors.message && formik.touched.message && formik.errors.message}
          multiline
          rows={4}
          inputProps={{ maxLength: 2000 }}
        />
        <LoadingButton
          size="medium"
          loading={sending}
          variant="contained"
          color="primary"
          type="submit"
          disabled={formik.isSubmitting}
          endIcon={<SendIcon fontSize="small" />}
        >
          {t('contact.send')}
        </LoadingButton>
      </Paper>
      <Typography
        component="span"
        variant="body2"
      >
        {t('contact.direct')}
        {' '}
        <CopyToClipboard
          t={t}
          toBeCopied="contact@labox-apps.com"
          child={(
            <Link
              sx={{
                fontWeight: 'bold',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 0.3,
              }}
            >
              contact@labox-apps.com
              <ContentCopyIcon sx={{ height: 18 }} />
            </Link>
      )}
        />
      </Typography>
    </Box>
  );
}
