import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import guide from 'staticFiles/guide2.jpeg';
import truck from 'staticFiles/truck.png';
import tank from 'staticFiles/tank.jpg';
import inventaire from 'staticFiles/inventories.jpg';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import optimix from 'staticFiles/optimix.jpg';

export default function OurSolutions() {
  const { t } = useTranslation();
  const lastSolutionRef = useRef(null);
  const mainBoxRef = useRef(null);
  const [bgHeight, setBgHeight] = useState(0);

  useEffect(() => {
    const updateBgHeight = () => {
      if (lastSolutionRef.current && mainBoxRef.current) {
        const buttonRect = lastSolutionRef.current.getBoundingClientRect();
        const containerRect = mainBoxRef.current.getBoundingClientRect();

        const buttonBottomRelativeToContainer = buttonRect.bottom - containerRect.top;
        const adjustedHeight = buttonBottomRelativeToContainer - (buttonRect.height / 2);

        setBgHeight(adjustedHeight);
      }
    };

    // Initial call to set the height
    updateBgHeight();

    // Add event listener for window resize
    window.addEventListener('resize', updateBgHeight);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', updateBgHeight);
    };
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        pt: 6,
        backgroundColor: 'background.default',
      }}
    >
      <Box
        ref={mainBoxRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            height: `${bgHeight}px`,
            zIndex: 1,
            width: '100%',
            backgroundColor: 'deepPrimary.main',
            position: 'absolute',
            top: 0,
          }}
        />
        <Typography
          variant="h2"
          color="primary.main"
          sx={{ mt: -5, fontWeight: 'bold', zIndex: 2 }}
        >
          {t('ourSolutions')}
        </Typography>
        <Typography variant="h6" sx={{ zIndex: 2 }}>
          <Trans
            i18nKey="many_clients"
            components={{
              1: <strong style={{ color: '#f4ad45' }} />,
            }}
          />
        </Typography>
        <Grid
          container
          spacing={1}
          rowSpacing={{ xs: 5, md: 10 }}
          sx={{
            px: 1,
            pt: 6,
            pb: 0,
            zIndex: 2,
          }}
        >
          <Grid item xs={6} md={6} lg={4}>
            <Solution
              title1={t('alcoholometry.title1')}
              title2={t('alcoholometry.title2')}
              text={t('alcoholometry.subtitle')}
              profileImg={guide}
              link="alcoholometry"
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <Solution
              title1={t('gc.title1')}
              title2={t('gc.title2')}
              text={t('gc.subtitle')}
              profileImg={tank}
              link="gauged-containers"
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <Solution
              title1={t('tt.title1')}
              title2={t('tt.title2')}
              text={t('tt.subtitle')}
              profileImg={truck}
              link="tanker-trucks"
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Solution
              title1={t('inventories.title1')}
              title2={t('inventories.title2')}
              text={t('inventories.subtitle')}
              profileImg={inventaire}
              link="inventories"
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Solution
              lastSolutionRef={lastSolutionRef}
              title1={t('optimix.title1')}
              title2={t('optimix.title2')}
              text={t('optimix.subtitle')}
              profileImg={optimix}
              link="optimix"
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function Solution({
  profileImg, title1, title2, text, link, lastSolutionRef,
}) {
  const { t } = useTranslation();

  return (
    <Box
      component={Link}
      to={link}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        textDecoration: 'none',
        color: 'inherit',
      }}
    >
      <Box
        component="img"
        sx={{
          width: { xs: '45vw', lg: 300, xl: 400 },
          height: { xs: '45vw', lg: 300 },
          maxWidth: 400,
          maxHeight: 400,
          borderRadius: 3,
          objectFit: 'cover',
          mb: { xs: 1, lg: 3 },
          opacity: 0.8,
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.03)',
          },
        }}
        src={profileImg}
        alt={title2}
      />
      <Typography
        sx={{
          fontWeight: 'bold',
          color: 'ourOrange.main',
        }}
        variant="h4"
      >
        {title1}
      </Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          color: 'ourOrange.main',
          mt: -1,
          height: { xs: 48, sm: 40 },
        }}
        variant="h6"
      >
        {title2}
      </Typography>
      <Typography sx={{ height: { xs: 72, sm: 48 } }}>
        {text}
      </Typography>
      <Button
        ref={lastSolutionRef}
        size="large"
        variant="contained"
        endIcon={<ArrowForwardIosIcon />}
        sx={{
          mt: 2,
          borderRadius: '20px',
        }}
      >
        {t('know_more')}
      </Button>
    </Box>
  );
}
