import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Box, Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import devices from 'staticFiles/devices.png';
import laboxapps from 'staticFiles/laboxapplications-white.svg';
import NavigationButtons from 'components/points/NavigationButtons';
import TypingTrans from './TypingTrans';
import { images } from './HeaderImages';
import QuickAccessButton from './QuickAccessButton';

export default function Header({ quickAccessesSection }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [switchInterval, setSwitchInterval] = useState(8000);
  const [dotWasClicked, setDotWasClicked] = useState(false);
  const intervalRef = useRef(null);
  const motoKeys = ['moto', 'inventory_promo', 'alco_promo'];
  const fontWidths = {
    moto: {
      xs: 'h5',
      sm: 'h4',
      md: 'h3',
      xl: 'h2',
    },
    inventory_promo: {
      xs: 'h5',
      sm: 'h5',
      md: 'h4',
      lg: 'h3',
      xl: 'h2',
    },
    alco_promo: {
      xs: 'h5',
      sm: 'h5',
      md: 'h4',
      lg: 'h3',
      xl: 'h2',
    },
  };

  const handleChangeImage = (newValue) => {
    setSwitchInterval(12000);
    setCurrentImageIndex(newValue);
    setDotWasClicked(true);
    resetInterval();
  };

  const handleArrowClick = (direction) => {
    const newIndex = direction === 'next'
      ? (currentImageIndex + 1) % images.length
      : (currentImageIndex - 1 + images.length) % images.length;
    handleChangeImage(newIndex);
  };

  const resetInterval = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setCurrentImageIndex((prevValue) => (prevValue + 1) % images.length);
      if (dotWasClicked) {
        setSwitchInterval(8000);
        setDotWasClicked(false);
      }
    }, switchInterval);
  }, [switchInterval, dotWasClicked]);

  useEffect(() => {
    resetInterval();

    return () => clearInterval(intervalRef.current);
  }, [resetInterval]);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop - 60,
      behavior: 'smooth',
    });
  };

  return (
    <Box
      key={currentImageIndex}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 6, md: 10 },
        px: 1,
        backgroundImage: `url(${images[currentImageIndex]})`,
        backgroundSize: 'cover',
        backgroundPosition: { xs: '88%', sm: 'center' },
        width: '100%',
        justifyContent: { xs: 'flex-start', md: 'center' },
        mt: { xs: -10, md: -12 },
        pt: { xs: 6, md: 15 },
        pb: { xs: 12, md: 15 },
        position: 'relative',
        height: '98vh',
        transition: 'background-image 0.2s ease-in-out',
        backgroundColor: 'deepPrimary.main',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${images[currentImageIndex]})`,
          backgroundSize: 'cover',
          backgroundPosition: { xs: '88%', sm: 'center' },
          animation: 'fadeInAndUnzoom 1.2s ease-out forwards',
          zIndex: 0,
        },
        '@keyframes fadeInAndUnzoom': {
          '0%': {
            transform: 'scale(1)',
          },
          '30%': {
            transform: 'scale(1.1)',
          },
          '100%': {
            transform: 'scale(1)',
          },
        },
      }}
    >
      {isSmallScreen && (
        <Box
          component="img"
          src={laboxapps}
          sx={{
            width: '90vw',
            maxWidth: 600,
            // backgroundColor: 'deepPrimary.main',
            p: 2,
          }}
        />
      )}
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: { xs: 6, sm: 2 },
        px: 1,
        justifyContent: 'center',
        mb: { xs: 4, sm: 0 },
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 3,
          minHeight: '100px',
        }}
        >
          <Typography
            color="ourOrange.main"
            sx={{
              backgroundColor: isSmallScreen ? 'primary.main' : 'transparent',
              typography: {
                xs: fontWidths[motoKeys[currentImageIndex]].xs,
                sm: fontWidths[motoKeys[currentImageIndex]].sm,
                md: fontWidths[motoKeys[currentImageIndex]].md,
              },
              width: { xs: '100vw', sm: '45vw' },
              maxWidth: 500,
              textAlign: { xs: 'center', sm: 'left' },
              p: 1,
              zIndex: 2,
            }}
          >
            <TypingTrans
              i18nKey={motoKeys[currentImageIndex]}
              highlightWords={['métiers', 'Businesses', 'inventaires', 'inventories',
                'douanes', 'customs']}
            />
          </Typography>
        </Box>
        <Box
          component="img"
          src={devices}
          sx={{
            width: { xs: '90vw', sm: '45vw' },
            maxWidth: 800,
            height: 'auto',
            opacity: currentImageIndex === 0 ? 1 : 0,
            zIndex: 1,
          }}
        />
      </Box>
      <QuickAccessButton
        scrollToRef={scrollToRef}
        quickAccessesSection={quickAccessesSection}
      />
      {/* <Button
        variant="contained"
        sx={{
          fontSize: { xs: 'small', lg: 'medium' },
          borderRadius: '20px',
          position: 'absolute',
          bottom: 30,
          alignSelf: { xs: 'flex-end', sm: 'center' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={() => navigate('/contact')}
      >
        {t('settings.contact_us')}
        <MailOutlineIcon />
      </Button> */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          display: 'flex',
          gap: 1,
          alignItems: 'center',
        }}
      >
        {images.map((image, index) => (
          <Box
            key={image}
            onClick={() => handleChangeImage(index)}
            sx={{
              width: currentImageIndex === index ? 18 : 10,
              height: currentImageIndex === index ? 10 : 10,
              borderRadius: currentImageIndex === index ? '4px' : '50%',
              backgroundColor: currentImageIndex === index ? 'primary.main' : 'grey.500',
              cursor: 'pointer',
            }}
          />
        ))}
      </Box>
      <NavigationButtons
        onPrevClick={() => handleArrowClick('prev')}
        onNextClick={() => handleArrowClick('next')}
      />
    </Box>
  );
}
