import React, { useRef } from 'react';
import { Box } from '@mui/material';
import Points from './points/Points';
import OurSolutions from './ourSolutions/OurSolutions';
import QuickAccesses from './QuickAccesses';
import TheTeam from './ourTeam/TheTeam';
import StayTuned from './StayTuned';
import Memberships from './MemberShips';
import Header from './header/Header';
import BackgroundImages from './BackgroundImages';

export default function Welcome() {
  const quickAccessesSection = useRef(null);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }}
    >
      <Box sx={{ zIndex: -10 }}>
        <BackgroundImages />
      </Box>
      <Header quickAccessesSection={quickAccessesSection} />
      <Points />
      <OurSolutions />
      <QuickAccesses quickAccessesSection={quickAccessesSection} />
      <TheTeam />
      <Box sx={{
        height: 120, backgroundColor: 'transparent', width: '100%',
      }}
      />
      <StayTuned />
      <Memberships />
    </Box>
  );
}
