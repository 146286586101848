import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardPoint from './CardPoint';

export default function AllPoints({ selectedGroup, onSelectGroup }) {
  const { t } = useTranslation();

  const points = {
    reliability: [
      { id: 'reliability1', title: 'reliability.1.title', text: 'reliability.1.text' },
      { id: 'reliability2', title: 'reliability.2.title', text: 'reliability.2.text' },
    ],
    ergonomic: [
      { id: 'ergonomic1', title: 'ergonomic.1.title', text: 'ergonomic.1.text' },
      { id: 'ergonomic2', title: 'ergonomic.2.title', text: 'ergonomic.2.text' },
    ],
    flexibility: [
      { id: 'flexibility1', title: 'flexibility.1.title', text: 'flexibility.1.text' },
      { id: 'flexibility2', title: 'flexibility.2.title', text: 'flexibility.2.text' },
    ],
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 3,
      justifyContent: 'center',
      width: '100%',
      zIndex: 2,
      py: { xs: 0, md: 8 },
    }}
    >
      {Object.entries(points).map(([groupName, groupPoints]) => (
        <Box
          key={groupName}
          data-group={groupName}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
          onMouseEnter={() => onSelectGroup(groupName)}
          onMouseLeave={() => onSelectGroup(null)}
        >
          {groupPoints.map((point) => (
            <Box
              key={point.id}
              sx={{
                textAlign: 'center',
              }}
            >
              <CardPoint
                title={t(point.title)}
                text={t(point.text)}
                isSelected={selectedGroup === groupName}
              />
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
