import { Box } from '@mui/material';
import React from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function NavigationButton({ direction, onClick }) {
  const isLeft = direction === 'prev';

  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'absolute',
        [isLeft ? 'left' : 'right']: 5,
        top: '50%',
        transform: 'translateY(-50%)',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '30px',
        py: 1,
        px: 0.5,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.3)',
        },
      }}
    >
      {isLeft ? (
        <ArrowBackIosNewIcon sx={{ color: 'lightgrey', fontSize: 18 }} />
      ) : (
        <ArrowForwardIosIcon sx={{ color: 'lightgrey', fontSize: 18 }} />
      )}
    </Box>
  );
}

function NavigationButtons({ onPrevClick, onNextClick }) {
  return (
    <>
      <NavigationButton direction="prev" onClick={onPrevClick} />
      <NavigationButton direction="next" onClick={onNextClick} />
    </>
  );
}

export default NavigationButtons;
