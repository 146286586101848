const seoConfig = {
  default: {
    titleKey: 'seo.default_title',
    descriptionKey: 'seo.default_description',
    pathname: '/',
  },
  alcoholometry: {
    titleKey: 'seo.alcoholometry_title',
    descriptionKey: 'seo.alcoholometry_description',
    pathname: '/alcoholometry',
  },
};

export default seoConfig;
