import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { Trans } from 'react-i18next';
import BackBoxes2 from 'components/BackBoxes2';
import lbxCube from 'staticFiles/lbx_cube.svg';
import SimplicityPoint from './SimplicityPoint';
import FlexibilityPoint from './FlexibilityPoint';
import RigoPoints from './RigoPoints';
import AllPoints from './AllPoints';
import NavigationButtons from './NavigationButtons';

export default function Points() {
  const [value, setValue] = useState(0);
  const [switchInterval, setSwitchInterval] = useState(8000);
  const [wasClicked, setWasClicked] = useState(false);
  const intervalRef = useRef(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const tabData = [
    {
      id: 0, label: 'reliability.title', number: '01.', content: 'Content for Point 1', color: '#3e6c79',
    },
    {
      id: 1, label: 'ergonomic.title', number: '02.', content: 'Content for Point 2', color: '#325b66',
    },
    {
      id: 2, label: 'flexibility.title', number: '03.', content: 'Content for Point 3', color: 'deepPrimary.main',
    },
  ];

  const getGroupIndex = (groupName) => {
    switch (groupName) {
      case 'reliability':
        return 0;
      case 'ergonomic':
        return 1;
      case 'flexibility':
        return 2;
      default:
        return 0;
    }
  };

  const updateSelection = (newValue) => {
    const valueIndex = typeof newValue === 'string' ? getGroupIndex(newValue) : newValue;
    setSwitchInterval(10000);
    setValue(valueIndex);
    setWasClicked(true);
    resetInterval();
  };

  const handleChange = (event, newValue) => {
    updateSelection(newValue);
  };

  const handleArrowClick = (direction) => {
    const newIndex = direction === 'next'
      ? (value + 1) % tabData.length
      : (value - 1 + tabData.length) % tabData.length;
    handleChange(null, newIndex);
  };

  const resetInterval = useCallback(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setValue((prevValue) => (prevValue + 1) % tabData.length);
      if (wasClicked) {
        setSwitchInterval(8000);
        setWasClicked(false);
      }
    }, switchInterval);
  }, [switchInterval, tabData.length, wasClicked]);

  useEffect(() => {
    resetInterval();
    return () => clearInterval(intervalRef.current);
  }, [resetInterval]);

  const getGroupName = (val) => {
    switch (val) {
      case 0:
        return 'reliability';
      case 1:
        return 'ergonomic';
      case 2:
        return 'flexibility';
      default:
        return null;
    }
  };

  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'background.default',
    }}
    >
      <Box sx={{
        width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'stretch', justifyContent: 'center',
      }}
      >
        {tabData.map((tab) => (
          <MyTab key={tab.id} tab={tab} selected={value === tab.id} handleChange={handleChange} />
        ))}
      </Box>
      <Box sx={{
        p: 3,
        minHeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
      }}
      >
        {/* <BackBoxes2 /> */}
        {isSmallScreen ? (
          <Box sx={{ zIndex: 10 }}>
            {value === 0 && <SimplicityPoint />}
            {value === 1 && <FlexibilityPoint />}
            {value === 2 && <RigoPoints />}
          </Box>
        ) : (
          <AllPoints
            selectedGroup={getGroupName(value)}
            onSelectGroup={updateSelection}
          />
        )}
        {isSmallScreen && (
          <NavigationButtons
            onPrevClick={() => handleArrowClick('prev')}
            onNextClick={() => handleArrowClick('next')}
          />
        )}
      </Box>
    </Box>
  );
}

function MyTab({ tab, selected, handleChange }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        display: 'flex',
        width: '33.33%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        backgroundColor: tab.color,
        pt: 8,
        pb: 5,
        flexDirection: 'column',
        position: 'relative',
        cursor: 'pointer',
      }}
      onClick={(e) => handleChange(e, tab.id)}
    >
      <Box sx={{
        position: 'relative',
        transform: selected && !isSmallScreen
          ? 'scale(1.1)' : 'scale(1)',
        transition: 'transform 0.3s ease-in-out',
      }}
      >
        <Box
          component="img"
          src={lbxCube}
          sx={{
            maxWidth: 60,
            width: '10vw',
            height: 'auto',
            position: 'absolute',
            zIndex: 1,
            left: { xs: '-1px', md: '-10px' },
            top: { xs: '-15px', md: '-30px' },
          }}
        />
        {/* <Typography
          color="secondary.main"
          sx={{
            fontSize: { xs: 60, md: 90 },
            fontWeight: 'bold',
            position: 'absolute',
            left: { xs: '-20px', md: '-40px' },
            top: { xs: '-30px', md: '-50px' },
            opacity: 0.3,
            zIndex: 1,
            filter: 'drop-shadow(2px 4px 6px black)',
          }}
        >
          {tab.number}
        </Typography> */}
        <Typography
          color={selected ? 'ourOrange.main' : 'white'}
          sx={{
            typography: { xs: 'h6', md: 'h5' },
            textAlign: 'left',
            mb: 3,
            zIndex: 10,
            position: 'relative',
          }}
        >
          <Trans i18nKey={tab.label} components={{ 1: <strong /> }} />
        </Typography>
      </Box>
      {selected && (
        <Box sx={{
          height: 5, backgroundColor: 'ourOrange.main', position: 'absolute', bottom: 0, width: '100%',
        }}
        />
      )}
    </Box>
  );
}
