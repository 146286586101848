import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import TextPoint from './TextPoint';

export default function RigoPoints({ selected }) {
  const [visiblePoints, setVisiblePoints] = useState(2);
  const { t } = useTranslation();
  const points = [
    { id: 'point1', title: 'ergonomic.1.title', text: 'ergonomic.1.text' },
    { id: 'point2', title: 'ergonomic.2.title', text: 'ergonomic.2.text' },
  ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setVisiblePoints((prev) => prev + 1);
  //   }, 600);

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: 3,
      flexWrap: 'wrap',
      justifyContent: 'center',
      width: '100%',
    }}
    >
      {points.map((point, index) => (
        <Box
          key={point.id}
          sx={{
            textAlign: 'center',
            visibility: visiblePoints > index ? 'visible' : 'hidden',
          }}
        >
          <TextPoint
            title={t(point.title)}
            text={t(point.text)}
            isSelected={selected === index}
          />
        </Box>
      ))}
    </Box>
  );
}
