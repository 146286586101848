import React, { useState } from 'react';
import {
  Box, Button, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import letter from 'staticFiles/lbx_letter.png';
import { addDoc, collection } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import useGlobal from 'global-state/store';

export default function StayTuned() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const db = useFirestore();
  const [, globalActions] = useGlobal();

  const sendEmail = async () => {
    const emailContent = `
    Nouvelle souscription à la mailing list, email: 
      ${email}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: '[Labox Apps, Souscription Mailing List]',
          text: emailContent,
        },
      },
    );
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubscribe = async () => {
    await sendEmail();
    globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', duration: 1000 });
    setEmail('');
  };

  return (
    <Box sx={{
      pt: 12,
      pb: 12,
      backgroundColor: 'background.default',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 3,
      }}
      >
        <Box
          component="img"
          sx={{
            width: 80,
            height: 'auto',
          }}
          src={letter}
        />
        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
          {t('stay_tuned')}
        </Typography>
        <Typography variant="h6" sx={{ mt: -2, color: 'ourOrange.main' }}>
          {t('subscribe_newsletter')}
        </Typography>
        <TextField
          fullWidth
          color="secondary"
          name="email"
          onChange={handleChange}
          value={email}
          label="email"
        />
        <Button
          sx={{
            borderRadius: '20px',
          }}
          onClick={handleSubscribe}
          variant="contained"
        >
          {t('subscribe')}
        </Button>
      </Box>
    </Box>
  );
}
