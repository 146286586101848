import React from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

function QuickAccessButton({ scrollToRef, quickAccessesSection }) {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="mediumgray"
      sx={{
        fontSize: { xs: 'small', lg: 'medium' },
        borderRadius: '20px',
        alignSelf: { xs: 'center', sm: 'flex-start' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        bottom: 30,
        left: 5,
        '& .MuiSvgIcon-root': {
          animation: 'bounce 2s infinite ease-in-out',
          '@keyframes bounce': {
            '0%, 75%': {
              transform: 'translateY(0)',
            },
            '80%': {
              transform: 'translateY(5px)',
            },
            '90%': {
              transform: 'translateY(-3px)',
            },
            '100%': {
              transform: 'translateY(0)',
            },
          },
        },
      }}
      onClick={() => scrollToRef(quickAccessesSection)}
    >
      {t('quick_accesses')}
      <KeyboardArrowDownIcon />
    </Button>
  );
}

export default QuickAccessButton;
